import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

type SaveType = "canvas" | "text";

export default defineNuxtPlugin(() => {
  const saveToPdf = async (
    HTMLElement: HTMLElement,
    filename = "file.pdf",
    type: SaveType = "canvas",
  ) => {
    if (!HTMLElement) {
      console.error("HTMLElement is required for generating PDF.");
      return;
    }

    const pdf = new jsPDF({
      compress: true,
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const padding = 10; // Add padding for better readability

    if (type === "text") {
      try {
        await pdf.html(HTMLElement, {
          callback: (pdf) => {
            const footerText = "Generated with jsPDF"; // Optional footer
            const fontSize = 10;
            const textWidth =
              (pdf.getStringUnitWidth(footerText) * fontSize) /
              pdf.internal.scaleFactor;
            const textX = (pageWidth - textWidth) / 2;
            const textY = pageHeight - 10;

            pdf.setFontSize(fontSize);
            pdf.text(footerText, textX, textY);
            pdf.save(filename);
          },
          x: padding,
          y: padding,
          width: pageWidth - 2 * padding,
          windowWidth: HTMLElement.scrollWidth,
        });
      } catch (error) {
        console.error("Error generating text-based PDF:", error);
      }
    } else {
      try {
        const canvas = await html2canvas(HTMLElement, {
          scale: 2, // Adjust scale for better quality
          useCORS: true,
          allowTaint: true,
        });

        const imgData = canvas.toDataURL("image/jpeg", 1.0);

        const imgWidth = canvas.width / 2; // Convert px to mm
        const imgHeight = canvas.height / 2;

        const aspectRatio = Math.min(
          (pageWidth - 2 * padding) / imgWidth,
          (pageHeight - 2 * padding) / imgHeight,
        );

        const scaledWidth = imgWidth * aspectRatio;
        const scaledHeight = imgHeight * aspectRatio;

        let remainingHeight = canvas.height; // Track remaining content height
        let yOffset = 0; // Y-offset for each page

        while (remainingHeight > 0) {
          pdf.addImage(
            imgData,
            "JPEG",
            padding,
            padding,
            scaledWidth,
            scaledHeight,
            undefined,
            "FAST", // Use fast rendering
          );

          remainingHeight -= scaledHeight; // Deduct visible height
          yOffset += pageHeight; // Move to the next page

          if (remainingHeight > 0) {
            // pdf.addPage();
          }
        }

        pdf.save(filename);
      } catch (error) {
        console.error("Error generating canvas-based PDF:", error);
      }
    }
  };

  return {
    provide: {
      saveToPdf,
    },
  };
});
