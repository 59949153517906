<template>
  <div class="tw-block xl:tw-hidden">
    <!-- Main Mobile Navigation header -->
    <nav class="tw-fixed tw-top-0 tw-z-[50] tw-w-full tw-border-b tw-bg-white">
      <div class="tw-px-4 md:tw-px-8">
        <div class="tw-flex tw-items-center tw-justify-between tw-py-3">
          <AppLogo type="dark" />

          <div @click.prevent="openSideMenu()" class="tw-cursor-pointer">
            <AppIcon name="menu-01" />
          </div>
        </div>
      </div>
    </nav>

    <!-- Mobile Side Menu -->
    <div
      v-show="isActive"
      @click.self="closeSideMenu()"
      class="side-menu-overlay tw-fixed tw-left-0 tw-top-0 tw-z-[20] tw-h-screen tw-w-full tw-cursor-pointer tw-bg-black/50"
    >
      <div
        class="tw-h-screen tw-w-[80%] tw-cursor-default tw-rounded-r-3xl tw-bg-white md:tw-w-[65%] lg:tw-w-[40%]"
      >
        <div class="tw-space-y-4 tw-p-5">
          <AppLogo type="dark" />

          <div class="tw-h-[70vh] tw-overflow-y-scroll">
            <DashboardSearch :isExpanded="true" theme="light" />

            <DashboardSideMenu theme="light" />

            <DashboardBottomSideMenu theme="light" />
          </div>

          <div class="tw-border-t tw-border-gray-200 tw-py-5">
            <DashboardBottomProfileMenu theme="light" />
          </div>
        </div>
      </div>
    </div>

    <!-- Spacer -->
    <div class="tw-py-8"></div>
  </div>
</template>

<script setup lang="ts">
const { $eventBus } = useNuxtApp();
const route = useRoute();
const isActive = ref<boolean>(false);
const openSideMenu = () => (isActive.value = true);
const closeSideMenu = () => (isActive.value = false);

// Watch for route change and then close side menu
watch(
  () => route.path,
  () => {
    closeSideMenu();
  },
);

// Listening for event to close side menu across web app
onMounted(() => {
  $eventBus.$on("closeMobileSideMenu", () => closeSideMenu());
});
</script>

<style lang="scss" scoped></style>
