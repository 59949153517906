import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

type SaveType = "canvas" | "text";

export default defineNuxtPlugin((nuxtApp) => {
  const saveToPdfTwo = async (
    HTMLElement: HTMLElement,
    filename = "file.pdf",
    type?: SaveType,
  ) => {
    if (HTMLElement) {
      if (type === "text") {
        // Create a new jsPDF instance
        const pdf = new jsPDF({
          compress: true,
          orientation: "p", // portrait
          unit: "px", // use pixels for better alignment
        });

        const padding = 20;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Add the HTML element to the PDF with padding
        await pdf.html(HTMLElement, {
          callback: function (pdf) {
            // Add custom text at the bottom of the PDF if needed
            const fontSize = 10; // Adjust as needed
            const text = ""; // Adjust text as needed
            pdf.setFontSize(fontSize);
            const textWidth =
              (pdf.getStringUnitWidth(text) * fontSize) /
              pdf.internal.scaleFactor;
            const textX = (pageWidth - textWidth) / 2;
            const textY = pageHeight - 10; // Adjust positioning as needed
            pdf.text(text, textX, textY);

            // Save the PDF or open it in a new window
            const blob = pdf.output("blob");
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
          },
          x: padding,
          y: padding,
          width: pageWidth - 2 * padding, // Adjust width for padding
          windowWidth: HTMLElement.scrollWidth, // Use scrollWidth for proper width rendering
        });
      } else {
        // Convert the HTMLElement to a canvas
        html2canvas(HTMLElement, {
          scale: 2.5, // Increase the scale for better quality
          useCORS: true,
          logging: true, // Enable logging for debugging
        }).then((canvas) => {
          // Get the dimensions of the canvas
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          // Convert the canvas to an image
          const img = canvas.toDataURL("image/jpeg", 1.0); // Set image quality to maximum

          // Create a new jsPDF instance with the canvas dimensions
          const pdf = new jsPDF({
            compress: true,
            orientation: "p", // landscape
            unit: "px", // use pixels for better alignment
            format: [canvasWidth, canvasHeight], // set dimensions
          });

          // Add the image to the PDF
          pdf.addImage(img, "JPEG", 0, 0, canvasWidth, canvasHeight);

          // Add custom text at the bottom of the canvas
          const fontSize = 10; // Adjust as needed
          const text = ""; // Adjust text as needed
          pdf.setFontSize(fontSize);
          const textWidth =
            (pdf.getStringUnitWidth(text) * fontSize) /
            pdf.internal.scaleFactor;
          const textX = (canvasWidth - textWidth) / 2;
          const textY = canvasHeight - 10; // Adjust positioning as needed
          pdf.text(text, textX, textY);

          // Save the PDF or open it in a new window
          // const blob = pdf.output("blob");
          // window.open(URL.createObjectURL(blob), "_blank");

          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      }
    }
  };

  return {
    provide: {
      saveToPdfTwo: saveToPdfTwo,
    },
  };
});
