const VIEW_ALL_SCHOOLS = "view_all_schools";
const CREATE_COLLECTIONS_ACCOUNT = "create_collections_account";
const ALL_AIRTIME = "all_airtime";
const ALL_WITHDRAWALS = "all_withdrawals";
const VIEW_PAYMENT = "view_payment";
const EXPORT_PAYMENT = "export_payment";
const VIEW_TRANSACTION_LOGS = "view_transaction_logs";
const EXPORT_TRANSACTION_LOGS = "export_transaction_logs";
const ALL_STATEMENTS = "all_statements";
const REQUEST_COWRI = "request_cowri";
const PAY_COWRI = "pay_cowri";
const MANAGE_COWRI = "manage_cowri";

const CREATE_INVOICE = "create_invoice";
const VIEW_INVOICE = "view_invoice";
const EDIT_INVOICE = "edit_invoice";
const DELETE_INVOICE = "delete_invoice";
const VIEW_ACTIVITY = "view_activity";
const EXPORT_ACTIVITY = "export_activity";
const VIEW_PAYOUT = "view_payout";
const EXPORT_PAYOUT = "export_payout";

const VIEW_BILLING_SESSION = "view_billing_session";
const CREATE_BILLING_SESSION = "create_billing_session";
const EDIT_BILLING_SESSION = "edit_billing_session";
const DELETE_BILLING_SESSION = "delete_billing_session";
const VIEW_BILLING_SYSTEM = "view_billing_system";
const CREATE_BILLING_SYSTEM = "create_billing_system";
const EDIT_BILLING_SYSTEM = "edit_billing_system";
const DELETE_BILLING_SYSTEM = "delete_billing_system";
const CREATE_BILLING_COLLECTIONS = "create_billing_collections";
const EDIT_BILLING_COLLECTIONS = "edit_billing_collections";
const EXPORT_BILLING_COLLECTIONS = "export_billing_collections";
const DELETE_BILLING_COLLECTIONS = "delete_billing_collections";
const CREATE_PAYLINK = "create_paylink";
const EDIT_PAYLINK = "edit_paylink";
const EXPORT_PAYLINK = "export_paylink";
const DELETE_PAYLINK = "delete_paylink";
const EDIT_STORE = "edit_store";
const DELETE_STORE = "delete_store";
const VIEW_DISCOUNT_SCHOLARSHIP = "view_discount_scholarship";
const APPLY_DISCOUNT_SCHOLARSHIP = "apply_discount_scholarship";
const EDIT_DISCOUNT_SCHOLARSHIP = "modify_discount_scholarship";
const DELETE_DISCOUNT_SCHOLARSHIP = "delete_discount_scholarship";
const CREATE_DISCOUNT_SCHOLARSHIP = "create_discount_scholarship";

const APPLY_IRECOVERY = "apply_iRecovery";
const VIEW_IRECOVERY = "view_iRecovery";

const EDIT_PERSONAL_PROFILE = "edit_personal_profile";
const VIEW_PERSONAL_PROFILE = "view_personal_profile";
const EDIT_SCHOOL_PROFILE = "edit_school_profile";
const VIEW_SCHOOL_PROFILE = "view_school_profile";
const EDIT_COMPLIANCE = "manage_compliance";
const VIEW_COMPLIANCE = "view_compliance";
const CREATE_GROUP = "create_group";
const VIEW_GROUP = "view_group";
const EDIT_GROUP = "edit_group";
const DELETE_GROUP = "delete_group";
const CREATE_TRANCHE = "create_tranche";
const VIEW_TRANCHE = "view_tranche";
const EDIT_TRANCHE = "edit_tranche";
const DELETE_TRANCHE = "delete_tranche";
const INVITE_TEAM_SUPER_ADMIN = "invite_team_super_admin";
const INVITE_TEAM_ADMIN = "invite_team_admin";
const INVITE_TEAM_FINANCE = "invite_team_finance";
const INVITE_TEAM_BURSAR = "invite_team_bursar";
const VIEW_INVITE_TEAM = "view_invite_team";
const ENABLE_2FA = "enable_2fa";
const DISABLE_2FA = "disable_2fa";
const MANAGE_PASSWORD = "manage_password";
const MANAGE_PIN = "manage_pin";
const CREATE_ADDRESS_BOOK = "create_address_book";
const VIEW_ADDRESS_BOOK = "view_address_book";
const EDIT_ADDRESS_BOOK = "edit_address_book";
const DELETE_ADDRESS_BOOK = "delete_address_book";
const CREATE_DISCOUNT = "create_discount";
const VIEW_DISCOUNT = "view_discount";
const EDIT_DISCOUNT = "edit_discount";
const DELETE_DISCOUNT = "delete_discount";
const MANAGE_SUBSCRIPTIONS = "manage_subscriptions";
const VIEW_SUBSCRIPTIONS = "view_subscriptions";

const VIEW_TABLE_LOGS = "view_table_logs";
const VIEW_ACTIVITY_LOGS = "view_activity_logs";

const VIEW_NOTIFICATIONS = "view_notifications";

const CREATE_BILLING_ACCOUNT = "create_billing_account";
const EDIT_BILLING_ACCOUNT = "edit_billing_account";
const DELETE_BILLING_ACCOUNT = "delete_billing_account";
const VIEW_BILLING_ACCOUNT = "view_billing_account";
const VIEW_BILLING_MANAGERS = "view_billing_managers";
const EXPORT_BILLING_MANAGERS = "export_billing_managers";
const CREATE_BILLING_MANAGERS = "create_billing_managers";
const CREATE_NOTICES = "create_notices";
const EDIT_NOTICES = "edit_notices";
const DELETE_NOTICES = "delete_notices";
const VIEW_NOTICES = "view_notices";

const REDEEM_PERKS = "redeem_perks";
const REDEEM_POINTS = "redeem_points";
const DONATE_POINTS = "donate_points";
const REFER = "refer";

const VIEW_BILLING_COLLECTIONS = "view_billing_collections";
const VIEW_PAYLINK = "view_paylink";
const VIEW_STORE = "view_store";

const SUPPORT = "support";

export default class ps {
  static VIEW_ALL_SCHOOLS = VIEW_ALL_SCHOOLS;
  static CREATE_COLLECTIONS_ACCOUNT = CREATE_COLLECTIONS_ACCOUNT;
  static ALL_AIRTIME = ALL_AIRTIME;
  static ALL_WITHDRAWALS = ALL_WITHDRAWALS;
  static VIEW_PAYMENT = VIEW_PAYMENT;
  static EXPORT_PAYMENT = EXPORT_PAYMENT;
  static VIEW_TRANSACTION_LOGS = VIEW_TRANSACTION_LOGS;
  static EXPORT_TRANSACTION_LOGS = EXPORT_TRANSACTION_LOGS;
  static ALL_STATEMENTS = ALL_STATEMENTS;
  static REQUEST_COWRI = REQUEST_COWRI;
  static PAY_COWRI = PAY_COWRI;
  static MANAGE_COWRI = MANAGE_COWRI;

  static CREATE_INVOICE = CREATE_INVOICE;
  static VIEW_INVOICE = VIEW_INVOICE;
  static EDIT_INVOICE = EDIT_INVOICE;
  static DELETE_INVOICE = DELETE_INVOICE;
  static VIEW_ACTIVITY = VIEW_ACTIVITY;
  static EXPORT_ACTIVITY = EXPORT_ACTIVITY;
  static VIEW_PAYOUT = VIEW_PAYOUT;
  static EXPORT_PAYOUT = EXPORT_PAYOUT;

  static VIEW_BILLING_SESSION = VIEW_BILLING_SESSION;
  static CREATE_BILLING_SESSION = CREATE_BILLING_SESSION;
  static EDIT_BILLING_SESSION = EDIT_BILLING_SESSION;
  static DELETE_BILLING_SESSION = DELETE_BILLING_SESSION;
  static VIEW_BILLING_SYSTEM = VIEW_BILLING_SYSTEM;
  static CREATE_BILLING_SYSTEM = CREATE_BILLING_SYSTEM;
  static EDIT_BILLING_SYSTEM = EDIT_BILLING_SYSTEM;
  static DELETE_BILLING_SYSTEM = DELETE_BILLING_SYSTEM;
  static CREATE_BILLING_COLLECTIONS = CREATE_BILLING_COLLECTIONS;
  static EDIT_BILLING_COLLECTIONS = EDIT_BILLING_COLLECTIONS;
  static EXPORT_BILLING_COLLECTIONS = EXPORT_BILLING_COLLECTIONS;
  static DELETE_BILLING_COLLECTIONS = DELETE_BILLING_COLLECTIONS;
  static CREATE_PAYLINK = CREATE_PAYLINK;
  static EDIT_PAYLINK = EDIT_PAYLINK;
  static EXPORT_PAYLINK = EXPORT_PAYLINK;
  static DELETE_PAYLINK = DELETE_PAYLINK;
  static EDIT_STORE = EDIT_STORE;
  static DELETE_STORE = DELETE_STORE;
  static VIEW_DISCOUNT_SCHOLARSHIP = VIEW_DISCOUNT_SCHOLARSHIP;
  static APPLY_DISCOUNT_SCHOLARSHIP = APPLY_DISCOUNT_SCHOLARSHIP;
  static EDIT_DISCOUNT_SCHOLARSHIP = EDIT_DISCOUNT_SCHOLARSHIP;
  static DELETE_DISCOUNT_SCHOLARSHIP = DELETE_DISCOUNT_SCHOLARSHIP;
  static CREATE_DISCOUNT_SCHOLARSHIP = CREATE_DISCOUNT_SCHOLARSHIP;

  static APPLY_IRECOVERY = APPLY_IRECOVERY;
  static VIEW_IRECOVERY = VIEW_IRECOVERY;

  static EDIT_PERSONAL_PROFILE = EDIT_PERSONAL_PROFILE;
  static VIEW_PERSONAL_PROFILE = VIEW_PERSONAL_PROFILE;
  static EDIT_SCHOOL_PROFILE = EDIT_SCHOOL_PROFILE;
  static VIEW_SCHOOL_PROFILE = VIEW_SCHOOL_PROFILE;
  static EDIT_COMPLIANCE = EDIT_COMPLIANCE;
  static VIEW_COMPLIANCE = VIEW_COMPLIANCE;
  static CREATE_GROUP = CREATE_GROUP;
  static VIEW_GROUP = VIEW_GROUP;
  static EDIT_GROUP = EDIT_GROUP;
  static DELETE_GROUP = DELETE_GROUP;
  static CREATE_TRANCHE = CREATE_TRANCHE;
  static VIEW_TRANCHE = VIEW_TRANCHE;
  static EDIT_TRANCHE = EDIT_TRANCHE;
  static DELETE_TRANCHE = DELETE_TRANCHE;
  static INVITE_TEAM_SUPER_ADMIN = INVITE_TEAM_SUPER_ADMIN;
  static INVITE_TEAM_ADMIN = INVITE_TEAM_ADMIN;
  static INVITE_TEAM_FINANCE = INVITE_TEAM_FINANCE;
  static INVITE_TEAM_BURSAR = INVITE_TEAM_BURSAR;
  static VIEW_INVITE_TEAM = VIEW_INVITE_TEAM;
  static ENABLE_2FA = ENABLE_2FA;
  static DISABLE_2FA = DISABLE_2FA;
  static MANAGE_PASSWORD = MANAGE_PASSWORD;
  static MANAGE_PIN = MANAGE_PIN;
  static CREATE_ADDRESS_BOOK = CREATE_ADDRESS_BOOK;
  static VIEW_ADDRESS_BOOK = VIEW_ADDRESS_BOOK;
  static EDIT_ADDRESS_BOOK = EDIT_ADDRESS_BOOK;
  static DELETE_ADDRESS_BOOK = DELETE_ADDRESS_BOOK;
  static CREATE_DISCOUNT = CREATE_DISCOUNT;
  static VIEW_DISCOUNT = VIEW_DISCOUNT;
  static EDIT_DISCOUNT = EDIT_DISCOUNT;
  static DELETE_DISCOUNT = DELETE_DISCOUNT;
  static MANAGE_SUBSCRIPTIONS = MANAGE_SUBSCRIPTIONS;
  static VIEW_SUBSCRIPTIONS = VIEW_SUBSCRIPTIONS;

  static VIEW_TABLE_LOGS = VIEW_TABLE_LOGS;
  static VIEW_ACTIVITY_LOGS = VIEW_ACTIVITY_LOGS;

  static VIEW_NOTIFICATIONS = VIEW_NOTIFICATIONS;

  static CREATE_BILLING_ACCOUNT = CREATE_BILLING_ACCOUNT;
  static EDIT_BILLING_ACCOUNT = EDIT_BILLING_ACCOUNT;
  static DELETE_BILLING_ACCOUNT = DELETE_BILLING_ACCOUNT;
  static VIEW_BILLING_ACCOUNT = VIEW_BILLING_ACCOUNT;
  static VIEW_BILLING_MANAGERS = VIEW_BILLING_MANAGERS;
  static EXPORT_BILLING_MANAGERS = EXPORT_BILLING_MANAGERS;
  static CREATE_BILLING_MANAGERS = CREATE_BILLING_MANAGERS;
  static CREATE_NOTICES = CREATE_NOTICES;
  static EDIT_NOTICES = EDIT_NOTICES;
  static DELETE_NOTICES = DELETE_NOTICES;
  static VIEW_NOTICES = VIEW_NOTICES;

  static REDEEM_PERKS = REDEEM_PERKS;
  static REDEEM_POINTS = REDEEM_POINTS;
  static DONATE_POINTS = DONATE_POINTS;
  static REFER = REFER;

  static VIEW_BILLING_COLLECTIONS = VIEW_BILLING_COLLECTIONS;
  static VIEW_PAYLINK = VIEW_PAYLINK;
  static VIEW_STORE = VIEW_STORE;

  static SUPPORT = SUPPORT;
}

export const superAdminOwnerPermissions = [
  // View All Schools
  VIEW_ALL_SCHOOLS,

  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  ALL_AIRTIME,
  ALL_WITHDRAWALS,
  VIEW_PAYMENT,
  EXPORT_PAYMENT,
  VIEW_TRANSACTION_LOGS,
  EXPORT_TRANSACTION_LOGS,
  ALL_STATEMENTS,
  REQUEST_COWRI,
  PAY_COWRI,
  MANAGE_COWRI,

  // payments
  CREATE_INVOICE,
  VIEW_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  VIEW_ACTIVITY,
  EXPORT_ACTIVITY,
  VIEW_PAYOUT,
  EXPORT_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  DELETE_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  DELETE_BILLING_SYSTEM,
  CREATE_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  VIEW_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  DELETE_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  EDIT_PAYLINK,
  VIEW_PAYLINK,
  EXPORT_PAYLINK,
  DELETE_PAYLINK,
  VIEW_STORE,
  EDIT_STORE,
  DELETE_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  EDIT_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,
  CREATE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  EDIT_SCHOOL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  EDIT_COMPLIANCE,
  CREATE_GROUP,
  VIEW_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  CREATE_TRANCHE,
  VIEW_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  INVITE_TEAM_SUPER_ADMIN,
  INVITE_TEAM_ADMIN,
  INVITE_TEAM_FINANCE,
  INVITE_TEAM_BURSAR,
  VIEW_INVITE_TEAM,
  ENABLE_2FA,
  DISABLE_2FA,
  MANAGE_PASSWORD,
  MANAGE_PIN,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  CREATE_DISCOUNT,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  MANAGE_SUBSCRIPTIONS,
  VIEW_SUBSCRIPTIONS,

  // dashboard
  VIEW_TABLE_LOGS,
  VIEW_ACTIVITY_LOGS,

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  EXPORT_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,
  REDEEM_POINTS,
  DONATE_POINTS,
  REFER,

  // supports
  SUPPORT,
];

export const superAdminAssignedPermissions = [
  // View All Schools
  VIEW_ALL_SCHOOLS,

  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  ALL_AIRTIME,
  ALL_WITHDRAWALS,
  VIEW_PAYMENT,
  EXPORT_PAYMENT,
  VIEW_TRANSACTION_LOGS,
  EXPORT_TRANSACTION_LOGS,
  ALL_STATEMENTS,
  REQUEST_COWRI,
  PAY_COWRI,

  // payments
  CREATE_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  VIEW_ACTIVITY,
  VIEW_INVOICE,
  EXPORT_ACTIVITY,
  VIEW_PAYOUT,
  EXPORT_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  DELETE_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  DELETE_BILLING_SYSTEM,
  VIEW_BILLING_COLLECTIONS,
  CREATE_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  DELETE_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  EDIT_PAYLINK,
  VIEW_PAYLINK,
  EXPORT_PAYLINK,
  DELETE_PAYLINK,
  VIEW_STORE,
  EDIT_STORE,
  DELETE_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  EDIT_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,
  CREATE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  EDIT_SCHOOL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  EDIT_COMPLIANCE,
  VIEW_COMPLIANCE,
  CREATE_GROUP,
  VIEW_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  CREATE_TRANCHE,
  VIEW_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  INVITE_TEAM_SUPER_ADMIN,
  INVITE_TEAM_ADMIN,
  INVITE_TEAM_FINANCE,
  INVITE_TEAM_BURSAR,
  VIEW_INVITE_TEAM,
  ENABLE_2FA,
  DISABLE_2FA,
  MANAGE_PASSWORD,
  MANAGE_PIN,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  MANAGE_SUBSCRIPTIONS,
  VIEW_SUBSCRIPTIONS,

  // dashboard
  VIEW_TABLE_LOGS,
  VIEW_ACTIVITY_LOGS,

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  EXPORT_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,
  REDEEM_POINTS,
  DONATE_POINTS,
  REFER,

  // supports
  SUPPORT,
];

export const financePermissions = [
  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  ALL_AIRTIME,
  ALL_WITHDRAWALS,
  VIEW_PAYMENT,
  EXPORT_PAYMENT,
  VIEW_TRANSACTION_LOGS,
  EXPORT_TRANSACTION_LOGS,
  ALL_STATEMENTS,
  REQUEST_COWRI,
  PAY_COWRI,
  MANAGE_COWRI,

  // payments
  VIEW_INVOICE,
  VIEW_ACTIVITY,
  EXPORT_ACTIVITY,
  VIEW_PAYOUT,
  EXPORT_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  CREATE_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  VIEW_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  VIEW_PAYLINK,
  EDIT_PAYLINK,
  EXPORT_PAYLINK,
  VIEW_STORE,
  EDIT_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  VIEW_GROUP,
  VIEW_TRANCHE,
  MANAGE_PASSWORD,
  MANAGE_PIN,
  VIEW_DISCOUNT,
  VIEW_SUBSCRIPTIONS,

  // dashboard

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,
  REDEEM_POINTS,
  DONATE_POINTS,
  REFER,

  // supports
  SUPPORT,
];

// New Roles and Permissions
export const superAdmininstratorPermissions = [
  // View All Schools
  VIEW_ALL_SCHOOLS,

  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  ALL_AIRTIME,
  ALL_WITHDRAWALS,
  VIEW_PAYMENT,
  EXPORT_PAYMENT,
  VIEW_TRANSACTION_LOGS,
  EXPORT_TRANSACTION_LOGS,
  ALL_STATEMENTS,
  REQUEST_COWRI,
  PAY_COWRI,
  MANAGE_COWRI,

  // payments
  CREATE_INVOICE,
  VIEW_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  VIEW_ACTIVITY,
  EXPORT_ACTIVITY,
  VIEW_PAYOUT,
  EXPORT_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  DELETE_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  DELETE_BILLING_SYSTEM,
  CREATE_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  VIEW_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  DELETE_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  EDIT_PAYLINK,
  VIEW_PAYLINK,
  EXPORT_PAYLINK,
  DELETE_PAYLINK,
  VIEW_STORE,
  EDIT_STORE,
  DELETE_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  EDIT_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,
  CREATE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  EDIT_SCHOOL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  EDIT_COMPLIANCE,
  CREATE_GROUP,
  VIEW_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  CREATE_TRANCHE,
  VIEW_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  INVITE_TEAM_SUPER_ADMIN,
  INVITE_TEAM_ADMIN,
  INVITE_TEAM_FINANCE,
  INVITE_TEAM_BURSAR,
  VIEW_INVITE_TEAM,
  ENABLE_2FA,
  DISABLE_2FA,
  MANAGE_PASSWORD,
  MANAGE_PIN,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  CREATE_DISCOUNT,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  MANAGE_SUBSCRIPTIONS,
  VIEW_SUBSCRIPTIONS,

  // dashboard
  VIEW_TABLE_LOGS,
  VIEW_ACTIVITY_LOGS,

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  EXPORT_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,
  REDEEM_POINTS,
  DONATE_POINTS,
  REFER,

  // supports
  SUPPORT,
];

export const administratorPermissions = [
  // View All Schools
  VIEW_ALL_SCHOOLS,

  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  ALL_AIRTIME,
  ALL_WITHDRAWALS,
  VIEW_PAYMENT,
  EXPORT_PAYMENT,
  VIEW_TRANSACTION_LOGS,
  EXPORT_TRANSACTION_LOGS,
  ALL_STATEMENTS,
  REQUEST_COWRI,
  PAY_COWRI,

  // payments
  CREATE_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
  VIEW_ACTIVITY,
  VIEW_INVOICE,
  EXPORT_ACTIVITY,
  VIEW_PAYOUT,
  EXPORT_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  DELETE_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  DELETE_BILLING_SYSTEM,
  VIEW_BILLING_COLLECTIONS,
  CREATE_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  DELETE_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  EDIT_PAYLINK,
  VIEW_PAYLINK,
  EXPORT_PAYLINK,
  DELETE_PAYLINK,
  VIEW_STORE,
  EDIT_STORE,
  DELETE_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  EDIT_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,
  CREATE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  EDIT_SCHOOL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  EDIT_COMPLIANCE,
  VIEW_COMPLIANCE,
  CREATE_GROUP,
  VIEW_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  CREATE_TRANCHE,
  VIEW_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  INVITE_TEAM_SUPER_ADMIN,
  INVITE_TEAM_ADMIN,
  INVITE_TEAM_FINANCE,
  INVITE_TEAM_BURSAR,
  VIEW_INVITE_TEAM,
  ENABLE_2FA,
  DISABLE_2FA,
  MANAGE_PASSWORD,
  MANAGE_PIN,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  MANAGE_SUBSCRIPTIONS,
  VIEW_SUBSCRIPTIONS,

  // dashboard
  VIEW_TABLE_LOGS,
  VIEW_ACTIVITY_LOGS,

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  DELETE_BILLING_ACCOUNT,
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  EXPORT_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,
  REDEEM_POINTS,
  DONATE_POINTS,
  REFER,

  // supports
  SUPPORT,
];

export const investoryPermissions = [
  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  VIEW_PAYMENT,
  VIEW_TRANSACTION_LOGS,

  // payments
  VIEW_INVOICE,
  EDIT_INVOICE,
  CREATE_INVOICE,
  VIEW_ACTIVITY,
  VIEW_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  CREATE_BILLING_COLLECTIONS,
  VIEW_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  VIEW_PAYLINK,
  EDIT_PAYLINK,
  EXPORT_PAYLINK,
  EDIT_STORE,
  VIEW_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  VIEW_GROUP,
  CREATE_GROUP,
  VIEW_TRANCHE,
  CREATE_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  MANAGE_PASSWORD,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  CREATE_DISCOUNT,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  VIEW_SUBSCRIPTIONS,

  // dashboard

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  VIEW_BILLING_ACCOUNT,
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  CREATE_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,

  // supports
  SUPPORT,
];

export const bursarPermissions = [
  // finance
  CREATE_COLLECTIONS_ACCOUNT,
  VIEW_PAYMENT,
  VIEW_TRANSACTION_LOGS,

  // payments
  VIEW_INVOICE,
  EDIT_INVOICE,
  CREATE_INVOICE,
  VIEW_ACTIVITY,
  VIEW_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  CREATE_BILLING_SESSION,
  EDIT_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  EDIT_BILLING_SYSTEM,
  CREATE_BILLING_SYSTEM,
  CREATE_BILLING_COLLECTIONS,
  VIEW_BILLING_COLLECTIONS,
  EDIT_BILLING_COLLECTIONS,
  EXPORT_BILLING_COLLECTIONS,
  CREATE_PAYLINK,
  VIEW_PAYLINK,
  EDIT_PAYLINK,
  EXPORT_PAYLINK,
  EDIT_STORE,
  VIEW_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,
  APPLY_DISCOUNT_SCHOLARSHIP,
  DELETE_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  APPLY_IRECOVERY,
  VIEW_IRECOVERY,

  // settings
  EDIT_PERSONAL_PROFILE,
  VIEW_PERSONAL_PROFILE,
  VIEW_SCHOOL_PROFILE,
  VIEW_GROUP,
  CREATE_GROUP,
  VIEW_TRANCHE,
  CREATE_TRANCHE,
  EDIT_TRANCHE,
  DELETE_TRANCHE,
  MANAGE_PASSWORD,
  CREATE_ADDRESS_BOOK,
  VIEW_ADDRESS_BOOK,
  EDIT_ADDRESS_BOOK,
  DELETE_ADDRESS_BOOK,
  CREATE_DISCOUNT,
  VIEW_DISCOUNT,
  EDIT_DISCOUNT,
  DELETE_DISCOUNT,
  VIEW_SUBSCRIPTIONS,

  // dashboard

  // notifications
  VIEW_NOTIFICATIONS,

  // administration
  VIEW_BILLING_ACCOUNT,
  CREATE_BILLING_ACCOUNT,
  EDIT_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  CREATE_BILLING_MANAGERS,
  CREATE_NOTICES,
  EDIT_NOTICES,
  DELETE_NOTICES,
  VIEW_NOTICES,

  // reward and perks
  REDEEM_PERKS,

  // supports
  SUPPORT,
];

export const guestPermissions = [
  // finance
  VIEW_PAYMENT,
  VIEW_TRANSACTION_LOGS,

  // payments
  VIEW_INVOICE,
  VIEW_ACTIVITY,
  VIEW_PAYOUT,

  // bursary
  VIEW_BILLING_SESSION,
  VIEW_BILLING_SYSTEM,
  VIEW_BILLING_COLLECTIONS,
  VIEW_PAYLINK,
  VIEW_STORE,
  VIEW_DISCOUNT_SCHOLARSHIP,

  // iRecovery
  VIEW_IRECOVERY,

  // settings
  VIEW_SCHOOL_PROFILE,
  VIEW_COMPLIANCE,
  VIEW_GROUP,
  VIEW_TRANCHE,
  VIEW_INVITE_TEAM,
  VIEW_ADDRESS_BOOK,
  VIEW_DISCOUNT,
  VIEW_SUBSCRIPTIONS,

  // dashboard
  VIEW_TABLE_LOGS,
  VIEW_ACTIVITY_LOGS,

  // notifications

  // administration
  VIEW_BILLING_ACCOUNT,
  VIEW_BILLING_MANAGERS,
  VIEW_NOTICES,

  // reward and perks

  // supports
  SUPPORT,
];
