
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as billing_45accountsUs7DNwr4zaMeta } from "/src/pages/dashboard/admin/billing-accounts.vue?macro=true";
import { default as _91id_93CKh9tVtFjeMeta } from "/src/pages/dashboard/admin/billing-managers/[id].vue?macro=true";
import { default as indexE9jjMaYNFxMeta } from "/src/pages/dashboard/admin/billing-managers/index.vue?macro=true";
import { default as _91id_93VQhfIJKLMPMeta } from "/src/pages/dashboard/admin/notices/[id].vue?macro=true";
import { default as draftsr7AvJXUxruMeta } from "/src/pages/dashboard/admin/notices/drafts.vue?macro=true";
import { default as indexRBWvRKUw4XMeta } from "/src/pages/dashboard/admin/notices/index.vue?macro=true";
import { default as add_45billing_45categoryzGdZ4AvuiGMeta } from "/src/pages/dashboard/bursary/billing-system/add-billing-category.vue?macro=true";
import { default as billing_45itemsgdMoOqnnjBMeta } from "/src/pages/dashboard/bursary/billing-system/billing-items.vue?macro=true";
import { default as edit_45billing_45categoryf4ZXM0IFAVMeta } from "/src/pages/dashboard/bursary/billing-system/edit-billing-category.vue?macro=true";
import { default as indexOKmQPue7oOMeta } from "/src/pages/dashboard/bursary/billing-system/index.vue?macro=true";
import { default as billingclwqjcsz1fMeta } from "/src/pages/dashboard/bursary/billing.vue?macro=true";
import { default as create_45invoiceRa6aAd5oIHMeta } from "/src/pages/dashboard/bursary/collections/create-invoice.vue?macro=true";
import { default as edit_45invoiceceYVoWQQifMeta } from "/src/pages/dashboard/bursary/collections/edit-invoice.vue?macro=true";
import { default as indexhxLcGAZInuMeta } from "/src/pages/dashboard/bursary/collections/index.vue?macro=true";
import { default as invoicesMvtrDpDBSxMeta } from "/src/pages/dashboard/bursary/collections/invoices.vue?macro=true";
import { default as discount_45and_45scholarship7lE937imEsMeta } from "/src/pages/dashboard/bursary/discount-and-scholarship.vue?macro=true";
import { default as invoices0pd2jLzJaSMeta } from "/src/pages/dashboard/bursary/invoices.vue?macro=true";
import { default as storeJQPK7mOPhOMeta } from "/src/pages/dashboard/bursary/store.vue?macro=true";
import { default as current_45crediteWg8SUwtCvMeta } from "/src/pages/dashboard/finance/cowri/current-credit.vue?macro=true";
import { default as indexSrQ4SZrq3SMeta } from "/src/pages/dashboard/finance/cowri/index.vue?macro=true";
import { default as offer_45updatesm5vx24E5SPMeta } from "/src/pages/dashboard/finance/cowri/offer-updates.vue?macro=true";
import { default as transactionAhOiGWae4KMeta } from "/src/pages/dashboard/finance/transaction.vue?macro=true";
import { default as cashKb3IptPgXoMeta } from "/src/pages/dashboard/finance/wallets/cash.vue?macro=true";
import { default as indexAcPYOoYe7wMeta } from "/src/pages/dashboard/finance/wallets/index.vue?macro=true";
import { default as indexxZF0QayL4AMeta } from "/src/pages/dashboard/index.vue?macro=true";
import { default as irecoveryDTDERasu3BMeta } from "/src/pages/dashboard/irecovery.vue?macro=true";
import { default as mainEY3y8wj8klMeta } from "/src/pages/dashboard/main.vue?macro=true";
import { default as new_45schooluOu6qLwJYpMeta } from "/src/pages/dashboard/new-school.vue?macro=true";
import { default as create_45school7oOiGN1AHsMeta } from "/src/pages/dashboard/onboarding/create-school.vue?macro=true";
import { default as indexHe6MrxZeIjMeta } from "/src/pages/dashboard/onboarding/index.vue?macro=true";
import { default as overview_45newN65LHbwBu1Meta } from "/src/pages/dashboard/overview-new.vue?macro=true";
import { default as overview1Rvt7MHEPWMeta } from "/src/pages/dashboard/overview.vue?macro=true";
import { default as _91paylink_id_93coL4mLx8iXMeta } from "/src/pages/dashboard/paylink/[paylink_id].vue?macro=true";
import { default as create_45paylinkE4Sjgjk0soMeta } from "/src/pages/dashboard/paylink/create-paylink.vue?macro=true";
import { default as indexY2M3RoWcJqMeta } from "/src/pages/dashboard/paylink/index.vue?macro=true";
import { default as authenticatorhAZHmOKUQvMeta } from "/src/pages/dashboard/payments/authenticator.vue?macro=true";
import { default as indexczSwQwo97LMeta } from "/src/pages/dashboard/payments/invoices/index.vue?macro=true";
import { default as indexjtyqrFqVMOMeta } from "/src/pages/dashboard/payments/settlement/index.vue?macro=true";
import { default as settlement_45list77jVGIRDh0Meta } from "/src/pages/dashboard/payments/settlement/settlement-list.vue?macro=true";
import { default as indexVfU3YYx0eTMeta } from "/src/pages/dashboard/rewards/index.vue?macro=true";
import { default as perksuZ3I8AelbIMeta } from "/src/pages/dashboard/rewards/perks.vue?macro=true";
import { default as point_45historye6tBfYNSztMeta } from "/src/pages/dashboard/rewards/point-history.vue?macro=true";
import { default as referralsuGThGRbCdpMeta } from "/src/pages/dashboard/rewards/referrals.vue?macro=true";
import { default as address_45bookET8AGamrmrMeta } from "/src/pages/dashboard/settings/address-book.vue?macro=true";
import { default as class_45indexc4lzwAld3iMeta } from "/src/pages/dashboard/settings/class-index.vue?macro=true";
import { default as complianceiOcVVIEy8qMeta } from "/src/pages/dashboard/settings/compliance.vue?macro=true";
import { default as discountJULP3GOhIFMeta } from "/src/pages/dashboard/settings/discount.vue?macro=true";
import { default as _91id_930HrzHxvMyHMeta } from "/src/pages/dashboard/settings/groups/[id].vue?macro=true";
import { default as indexV7DjinU2o5Meta } from "/src/pages/dashboard/settings/groups/index.vue?macro=true";
import { default as migrationAfm0YlLtJGMeta } from "/src/pages/dashboard/settings/groups/migration.vue?macro=true";
import { default as indexXuLkcsVaqaMeta } from "/src/pages/dashboard/settings/index.vue?macro=true";
import { default as payoutIL4H0ARd8PMeta } from "/src/pages/dashboard/settings/payout.vue?macro=true";
import { default as securitysYzhiWI31uMeta } from "/src/pages/dashboard/settings/security.vue?macro=true";
import { default as _91id_93Zsx6l10gYrMeta } from "/src/pages/dashboard/settings/staff-groups/[id].vue?macro=true";
import { default as indexu1i1uKoFyXMeta } from "/src/pages/dashboard/settings/staff-groups/index.vue?macro=true";
import { default as subscriptionoAhuHgDFg8Meta } from "/src/pages/dashboard/settings/subscription.vue?macro=true";
import { default as indexFVhQ97ZVL3Meta } from "/src/pages/dashboard/settings/team/index.vue?macro=true";
import { default as roles33npDd6tKzMeta } from "/src/pages/dashboard/settings/team/roles.vue?macro=true";
import { default as tranchesbk3FX6I01Meta } from "/src/pages/dashboard/settings/tranche.vue?macro=true";
import { default as indexad8WrmkGJCMeta } from "/src/pages/dashboard/settings/vendor-groups/index.vue?macro=true";
import { default as supportjuE0s16eXQMeta } from "/src/pages/dashboard/support.vue?macro=true";
import { default as forgot_45passwordhkQDFGWAYaMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as verify_45tokenPFUjwpH3ISMeta } from "/src/pages/invitation/verify-token.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as sign_45upjeNWAFVoRzMeta } from "/src/pages/sign-up.vue?macro=true";
export default [
  {
    name: "dashboard-admin-billing-accounts",
    path: "/dashboard/admin/billing-accounts",
    meta: billing_45accountsUs7DNwr4zaMeta || {},
    component: () => import("/src/pages/dashboard/admin/billing-accounts.vue")
  },
  {
    name: "dashboard-admin-billing-managers-id",
    path: "/dashboard/admin/billing-managers/:id()",
    meta: _91id_93CKh9tVtFjeMeta || {},
    component: () => import("/src/pages/dashboard/admin/billing-managers/[id].vue")
  },
  {
    name: "dashboard-admin-billing-managers",
    path: "/dashboard/admin/billing-managers",
    meta: indexE9jjMaYNFxMeta || {},
    component: () => import("/src/pages/dashboard/admin/billing-managers/index.vue")
  },
  {
    name: "dashboard-admin-notices-id",
    path: "/dashboard/admin/notices/:id()",
    meta: _91id_93VQhfIJKLMPMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/[id].vue")
  },
  {
    name: "dashboard-admin-notices-drafts",
    path: "/dashboard/admin/notices/drafts",
    meta: draftsr7AvJXUxruMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/drafts.vue")
  },
  {
    name: "dashboard-admin-notices",
    path: "/dashboard/admin/notices",
    meta: indexRBWvRKUw4XMeta || {},
    component: () => import("/src/pages/dashboard/admin/notices/index.vue")
  },
  {
    name: "dashboard-bursary-billing-system-add-billing-category",
    path: "/dashboard/bursary/billing-system/add-billing-category",
    meta: add_45billing_45categoryzGdZ4AvuiGMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/add-billing-category.vue")
  },
  {
    name: "dashboard-bursary-billing-system-billing-items",
    path: "/dashboard/bursary/billing-system/billing-items",
    meta: billing_45itemsgdMoOqnnjBMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/billing-items.vue")
  },
  {
    name: "dashboard-bursary-billing-system-edit-billing-category",
    path: "/dashboard/bursary/billing-system/edit-billing-category",
    meta: edit_45billing_45categoryf4ZXM0IFAVMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/edit-billing-category.vue")
  },
  {
    name: "dashboard-bursary-billing-system",
    path: "/dashboard/bursary/billing-system",
    meta: indexOKmQPue7oOMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing-system/index.vue")
  },
  {
    name: "dashboard-bursary-billing",
    path: "/dashboard/bursary/billing",
    meta: billingclwqjcsz1fMeta || {},
    component: () => import("/src/pages/dashboard/bursary/billing.vue")
  },
  {
    name: "dashboard-bursary-collections-create-invoice",
    path: "/dashboard/bursary/collections/create-invoice",
    meta: create_45invoiceRa6aAd5oIHMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/create-invoice.vue")
  },
  {
    name: "dashboard-bursary-collections-edit-invoice",
    path: "/dashboard/bursary/collections/edit-invoice",
    meta: edit_45invoiceceYVoWQQifMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/edit-invoice.vue")
  },
  {
    name: "dashboard-bursary-collections",
    path: "/dashboard/bursary/collections",
    meta: indexhxLcGAZInuMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/index.vue")
  },
  {
    name: "dashboard-bursary-collections-invoices",
    path: "/dashboard/bursary/collections/invoices",
    meta: invoicesMvtrDpDBSxMeta || {},
    component: () => import("/src/pages/dashboard/bursary/collections/invoices.vue")
  },
  {
    name: "dashboard-bursary-discount-and-scholarship",
    path: "/dashboard/bursary/discount-and-scholarship",
    meta: discount_45and_45scholarship7lE937imEsMeta || {},
    component: () => import("/src/pages/dashboard/bursary/discount-and-scholarship.vue")
  },
  {
    name: "dashboard-bursary-invoices",
    path: "/dashboard/bursary/invoices",
    meta: invoices0pd2jLzJaSMeta || {},
    component: () => import("/src/pages/dashboard/bursary/invoices.vue")
  },
  {
    name: "dashboard-bursary-store",
    path: "/dashboard/bursary/store",
    meta: storeJQPK7mOPhOMeta || {},
    component: () => import("/src/pages/dashboard/bursary/store.vue")
  },
  {
    name: "dashboard-finance-cowri-current-credit",
    path: "/dashboard/finance/cowri/current-credit",
    meta: current_45crediteWg8SUwtCvMeta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/current-credit.vue")
  },
  {
    name: "dashboard-finance-cowri",
    path: "/dashboard/finance/cowri",
    meta: indexSrQ4SZrq3SMeta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/index.vue")
  },
  {
    name: "dashboard-finance-cowri-offer-updates",
    path: "/dashboard/finance/cowri/offer-updates",
    meta: offer_45updatesm5vx24E5SPMeta || {},
    component: () => import("/src/pages/dashboard/finance/cowri/offer-updates.vue")
  },
  {
    name: "dashboard-finance-transaction",
    path: "/dashboard/finance/transaction",
    meta: transactionAhOiGWae4KMeta || {},
    component: () => import("/src/pages/dashboard/finance/transaction.vue")
  },
  {
    name: "dashboard-finance-wallets-cash",
    path: "/dashboard/finance/wallets/cash",
    meta: cashKb3IptPgXoMeta || {},
    component: () => import("/src/pages/dashboard/finance/wallets/cash.vue")
  },
  {
    name: "dashboard-finance-wallets",
    path: "/dashboard/finance/wallets",
    meta: indexAcPYOoYe7wMeta || {},
    component: () => import("/src/pages/dashboard/finance/wallets/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexxZF0QayL4AMeta || {},
    component: () => import("/src/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-irecovery",
    path: "/dashboard/irecovery",
    meta: irecoveryDTDERasu3BMeta || {},
    component: () => import("/src/pages/dashboard/irecovery.vue")
  },
  {
    name: "dashboard-main",
    path: "/dashboard/main",
    meta: mainEY3y8wj8klMeta || {},
    component: () => import("/src/pages/dashboard/main.vue")
  },
  {
    name: "dashboard-new-school",
    path: "/dashboard/new-school",
    component: () => import("/src/pages/dashboard/new-school.vue")
  },
  {
    name: "dashboard-onboarding-create-school",
    path: "/dashboard/onboarding/create-school",
    component: () => import("/src/pages/dashboard/onboarding/create-school.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    component: () => import("/src/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-overview-new",
    path: "/dashboard/overview-new",
    meta: overview_45newN65LHbwBu1Meta || {},
    component: () => import("/src/pages/dashboard/overview-new.vue")
  },
  {
    name: "dashboard-overview",
    path: "/dashboard/overview",
    meta: overview1Rvt7MHEPWMeta || {},
    component: () => import("/src/pages/dashboard/overview.vue")
  },
  {
    name: "dashboard-paylink-paylink_id",
    path: "/dashboard/paylink/:paylink_id()",
    meta: _91paylink_id_93coL4mLx8iXMeta || {},
    component: () => import("/src/pages/dashboard/paylink/[paylink_id].vue")
  },
  {
    name: "dashboard-paylink-create-paylink",
    path: "/dashboard/paylink/create-paylink",
    meta: create_45paylinkE4Sjgjk0soMeta || {},
    component: () => import("/src/pages/dashboard/paylink/create-paylink.vue")
  },
  {
    name: "dashboard-paylink",
    path: "/dashboard/paylink",
    meta: indexY2M3RoWcJqMeta || {},
    component: () => import("/src/pages/dashboard/paylink/index.vue")
  },
  {
    name: "dashboard-payments-authenticator",
    path: "/dashboard/payments/authenticator",
    meta: authenticatorhAZHmOKUQvMeta || {},
    component: () => import("/src/pages/dashboard/payments/authenticator.vue")
  },
  {
    name: "dashboard-payments-invoices",
    path: "/dashboard/payments/invoices",
    meta: indexczSwQwo97LMeta || {},
    component: () => import("/src/pages/dashboard/payments/invoices/index.vue")
  },
  {
    name: "dashboard-payments-settlement",
    path: "/dashboard/payments/settlement",
    meta: indexjtyqrFqVMOMeta || {},
    component: () => import("/src/pages/dashboard/payments/settlement/index.vue")
  },
  {
    name: "dashboard-payments-settlement-settlement-list",
    path: "/dashboard/payments/settlement/settlement-list",
    meta: settlement_45list77jVGIRDh0Meta || {},
    component: () => import("/src/pages/dashboard/payments/settlement/settlement-list.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexVfU3YYx0eTMeta || {},
    component: () => import("/src/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-rewards-perks",
    path: "/dashboard/rewards/perks",
    meta: perksuZ3I8AelbIMeta || {},
    component: () => import("/src/pages/dashboard/rewards/perks.vue")
  },
  {
    name: "dashboard-rewards-point-history",
    path: "/dashboard/rewards/point-history",
    meta: point_45historye6tBfYNSztMeta || {},
    component: () => import("/src/pages/dashboard/rewards/point-history.vue")
  },
  {
    name: "dashboard-rewards-referrals",
    path: "/dashboard/rewards/referrals",
    meta: referralsuGThGRbCdpMeta || {},
    component: () => import("/src/pages/dashboard/rewards/referrals.vue")
  },
  {
    name: "dashboard-settings-address-book",
    path: "/dashboard/settings/address-book",
    meta: address_45bookET8AGamrmrMeta || {},
    component: () => import("/src/pages/dashboard/settings/address-book.vue")
  },
  {
    name: "dashboard-settings-class-index",
    path: "/dashboard/settings/class-index",
    meta: class_45indexc4lzwAld3iMeta || {},
    component: () => import("/src/pages/dashboard/settings/class-index.vue")
  },
  {
    name: "dashboard-settings-compliance",
    path: "/dashboard/settings/compliance",
    meta: complianceiOcVVIEy8qMeta || {},
    component: () => import("/src/pages/dashboard/settings/compliance.vue")
  },
  {
    name: "dashboard-settings-discount",
    path: "/dashboard/settings/discount",
    meta: discountJULP3GOhIFMeta || {},
    component: () => import("/src/pages/dashboard/settings/discount.vue")
  },
  {
    name: "dashboard-settings-groups-id",
    path: "/dashboard/settings/groups/:id()",
    meta: _91id_930HrzHxvMyHMeta || {},
    component: () => import("/src/pages/dashboard/settings/groups/[id].vue")
  },
  {
    name: "dashboard-settings-groups",
    path: "/dashboard/settings/groups",
    meta: indexV7DjinU2o5Meta || {},
    component: () => import("/src/pages/dashboard/settings/groups/index.vue")
  },
  {
    name: "dashboard-settings-groups-migration",
    path: "/dashboard/settings/groups/migration",
    meta: migrationAfm0YlLtJGMeta || {},
    component: () => import("/src/pages/dashboard/settings/groups/migration.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: indexXuLkcsVaqaMeta || {},
    component: () => import("/src/pages/dashboard/settings/index.vue")
  },
  {
    name: "dashboard-settings-payout",
    path: "/dashboard/settings/payout",
    meta: payoutIL4H0ARd8PMeta || {},
    component: () => import("/src/pages/dashboard/settings/payout.vue")
  },
  {
    name: "dashboard-settings-security",
    path: "/dashboard/settings/security",
    meta: securitysYzhiWI31uMeta || {},
    component: () => import("/src/pages/dashboard/settings/security.vue")
  },
  {
    name: "dashboard-settings-staff-groups-id",
    path: "/dashboard/settings/staff-groups/:id()",
    meta: _91id_93Zsx6l10gYrMeta || {},
    component: () => import("/src/pages/dashboard/settings/staff-groups/[id].vue")
  },
  {
    name: "dashboard-settings-staff-groups",
    path: "/dashboard/settings/staff-groups",
    meta: indexu1i1uKoFyXMeta || {},
    component: () => import("/src/pages/dashboard/settings/staff-groups/index.vue")
  },
  {
    name: "dashboard-settings-subscription",
    path: "/dashboard/settings/subscription",
    meta: subscriptionoAhuHgDFg8Meta || {},
    component: () => import("/src/pages/dashboard/settings/subscription.vue")
  },
  {
    name: "dashboard-settings-team",
    path: "/dashboard/settings/team",
    meta: indexFVhQ97ZVL3Meta || {},
    component: () => import("/src/pages/dashboard/settings/team/index.vue")
  },
  {
    name: "dashboard-settings-team-roles",
    path: "/dashboard/settings/team/roles",
    meta: roles33npDd6tKzMeta || {},
    component: () => import("/src/pages/dashboard/settings/team/roles.vue")
  },
  {
    name: "dashboard-settings-tranche",
    path: "/dashboard/settings/tranche",
    meta: tranchesbk3FX6I01Meta || {},
    component: () => import("/src/pages/dashboard/settings/tranche.vue")
  },
  {
    name: "dashboard-settings-vendor-groups",
    path: "/dashboard/settings/vendor-groups",
    meta: indexad8WrmkGJCMeta || {},
    component: () => import("/src/pages/dashboard/settings/vendor-groups/index.vue")
  },
  {
    name: "dashboard-support",
    path: "/dashboard/support",
    meta: supportjuE0s16eXQMeta || {},
    component: () => import("/src/pages/dashboard/support.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "invitation-verify-token",
    path: "/invitation/verify-token",
    component: () => import("/src/pages/invitation/verify-token.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upjeNWAFVoRzMeta || {},
    component: () => import("/src/pages/sign-up.vue")
  }
]