<template>
  <div class="tw-relative">
    <section
      @mouseleave="isMenuActive = false"
      ref="menudropdown"
      v-show="isMenuActive"
      class="tw-absolute tw-bottom-0 tw-top--8 tw-z-[20] tw-h-max tw-w-full tw-divide-y tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-shadow-md lg:tw-block"
    >
      <!-- Profiles -->
      <div class="tw-space-y-5 tw-p-5">
        <!-- User Profile -->
        <div class="tw-flex tw-h-max tw-items-center tw-space-x-4">
          <div
            class="tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-50"
          >
            <Icon
              name="carbon:user"
              class="tw-text-xl tw-text-primary-600"
              v-if="store.user?.photo == null"
            />

            <NuxtImg
              v-else
              :src="store.user?.photo"
              :alt="store.user?.firstname"
              class="tw-h-full tw-w-full tw-rounded-full tw-object-cover"
            />
          </div>
          <div class="tw-text-sm xl:tw-block">
            <p class="tw-font-semibold tw-capitalize tw-text-gray-700">
              {{ store.user?.firstname }} {{ store.user?.lastname }}
            </p>
            <p class="tw-lowercase tw-text-gray-500 xl:tw-text-xs">
              {{ store.active_email }}
            </p>
          </div>
        </div>

        <!-- School Profile -->
        <div class="tw-flex tw-h-max tw-items-center tw-space-x-4">
          <div
            class="tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-50"
          >
            <NuxtImg
              v-if="store?.active_school?.logo == null"
              src="https://files.sproutly.africa/assets/images/onboarding/dummy-school-logo.png"
              :alt="store?.active_school?.name"
              class="tw-h-full tw-w-full tw-rounded-full tw-object-cover"
            />

            <NuxtImg
              v-else
              :src="store?.active_school?.logo"
              :alt="store?.active_school?.name"
              class="tw-h-full tw-w-full tw-rounded-full tw-object-cover"
            />
          </div>
          <div
            class="tw-text-sm xl:tw-block"
            :class="[isExpanded ? '' : 'xl:tw-hidden']"
          >
            <p class="tw-font-semibold tw-capitalize tw-text-gray-700">
              {{ store?.active_school?.name }}
            </p>
            <p class="tw-lowercase tw-text-gray-500 xl:tw-text-xs">
              {{ store?.active_school?.code }}
            </p>
          </div>
        </div>
      </div>

      <!-- Schools -->
      <div
        class="tw-space-y-5 tw-p-5 tw-font-medium tw-text-gray-700"
        v-can="[ps.VIEW_ALL_SCHOOLS]"
      >
        <!-- All Schools -->
        <div v-if="isSchoolsLoading === 'pending'">
          <SkeletonList v-for="(n, i) in 3" :key="i" />
        </div>

        <div v-else class="tw-space-y-5 tw-capitalize">
          <div
            v-for="(school, i) in schools"
            @click.prevent="switchSchool(school.id)"
            :key="i"
            class="tw-cursor-pointer hover:tw-font-semibold"
          >
            <span>{{ school.name }}</span>
          </div>
        </div>

        <div>
          <NuxtLink to="/dashboard/onboarding">
            <div class="tw-cursor-pointer hover:tw-font-semibold">
              Add a school
            </div>
          </NuxtLink>
        </div>
      </div>

      <!-- Settings -->
      <div class="tw-space-y-5 tw-p-5 tw-font-medium tw-text-gray-700">
        <div>
          <NuxtLink to="/dashboard/support">
            <div class="tw-cursor-pointer hover:tw-font-semibold">Support</div>
          </NuxtLink>
        </div>

        <div>
          <NuxtLink to="/dashboard/settings">
            <div class="tw-cursor-pointer hover:tw-font-semibold">Settings</div>
          </NuxtLink>
        </div>
      </div>

      <div class="tw-space-y-5 tw-p-5 tw-font-medium tw-text-gray-700">
        <div
          @click.prevent="logoutMerchantAccount"
          class="tw-cursor-pointer hover:tw-font-semibold"
        >
          <span>Logout</span>
        </div>
      </div>
    </section>

    <section
      class="tw-flex tw-h-max tw-items-center tw-justify-between tw-space-x-2 2xl:tw-justify-between"
    >
      <div
        @click.prevent="isMenuActive = true"
        class="tw-flex tw-h-max tw-cursor-pointer tw-items-center tw-space-x-4"
      >
        <div
          class="tw-flex tw-h-[40px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-50"
        >
          <Icon
            name="carbon:user"
            class="tw-text-xl tw-text-primary-600"
            v-if="store.user?.photo == null"
          />

          <NuxtImg
            v-else
            :src="store.user?.photo"
            :alt="store.user?.firstname"
            class="tw-h-full tw-w-full tw-rounded-full tw-object-cover"
          />
        </div>
        <div
          class="tw-text-sm xl:tw-block"
          :class="[isExpanded ? '' : 'xl:tw-hidden']"
        >
          <p
            :class="theme === 'light' ? 'tw-text-gray-900' : 'tw-text-white'"
            class="tw-font-semibold tw-capitalize"
          >
            {{ store.user?.firstname }} {{ store.user?.lastname }}
          </p>
          <p
            :class="theme === 'light' ? 'tw-text-gray-700' : 'tw-text-gray-100'"
            class="tw-lowercase xl:tw-text-xs"
          >
            {{ store?.active_email }}
          </p>
        </div>
      </div>

      <div
        class="tw-cursor-pointer"
        :class="[isExpanded ? 'xl:tw-block' : 'xl:tw-hidden']"
        @click.prevent="logoutMerchantAccount"
      >
        <Icon name="ic:round-logout" class="tw-text-xl tw-text-gray-400" />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~/store";
import { onClickOutside } from "@vueuse/core";
import ps from "~/utils/permissions";

const store = useStore();
const router = useRouter();
const { $axios, $toast } = useNuxtApp();

withDefaults(
  defineProps<{
    isExpanded?: boolean;
    theme: string;
  }>(),
  {
    theme: "dark",
  },
);

// Type for school
interface School {
  id: string;
  global_id: string;
  country_code: string;
  name: string;
  slug: string;
  referral_code: string;
  qrcode: string | null;
  unique_link: string | null;
  code: string;
  logo: string | null;
  public_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  user_id: string;
}

const isMenuActive = ref<boolean>(false);
const menudropdown = ref(null);
const nuxtApp = useNuxtApp();

const switchSchool = async (id: string) => {
  try {
    nuxtApp.callHook("page:start");

    const response = await $axios.post(`/schools/manage/${id}/switch`);

    const { status, data, message } = response.data;

    if (status === true || status === "successful") {
      const { team_id } = data;

      store.$patch({
        active_school: data,
        team_id: team_id,
      });

      isMenuActive.value = false;
      router.push("/dashboard");
      $toast.success(message);
    }
  } catch (error: any) {
    if (error.response) {
      $toast.error(error.response.data.message);
    }
  } finally {
    nuxtApp.callHook("page:finish");
  }
};

const getSchools = async () => {
  try {
    nuxtApp.callHook("page:start");

    const response = await $axios.get(`/schools/manage`);

    const { status, data, message } = response.data;

    if (status === true || status === "successful") {
      store.$patch({
        schools: data.filter((school: School) => school !== null),
      });
      return data.filter((school: School) => school !== null);
    } else {
      throw new Error(message || "Unexpected response format");
    }
  } catch (error: any) {
    if (error.response) {
      console.error(error.response.data.message);
    }
  } finally {
    nuxtApp.callHook("page:finish");
  }
};

const logoutMerchantAccount = async () => {
  let confirmation = confirm("Are you sure you want to logout?");
  if (confirmation) {
    store.$reset();
    sessionStorage.clear();
    router.push("/login");
  } else {
    return;
  }
};

onClickOutside(menudropdown, () => {
  isMenuActive.value = false;
});

const {
  data: schools,
  status: isSchoolsLoading,
  refresh: refreshSchools,
} = useAsyncData("schools", getSchools);
</script>

<style lang="scss" scoped></style>
